<template>
    <div class="post offers btm-menu">
        <Breadcrumb :background="true" content="Kita- & Schulfinder | Kita"/>

        <div class="profile">
            <div class="container-fluid">
                <div class="profile-main">

                    <profile-image-carousel v-if="slides!==null" :slides="slides"/>

                    <div class="profile-main__info">
                        <h5>{{traeger}}</h5>
                        <share-button v-if="!showShareBtnInTitle"/>

                        <div class="profile-main__info-title">
                            <h3>{{traeger}}</h3>
                            <share-button v-if="showShareBtnInTitle"/>
                        </div>

                        <info-block target="desktop" :content="content" :infos="infoBlockInfos"/>

                    </div>
                </div>
            </div>
        </div>

        <div class="section about">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-lg-7">
                        <div class="row">
                            <div class="col-12">
                                <div class="about-content d-lg-none">
                                    <p v-if="showMoreDescription" v-html="shortDescription"></p>
                                    <p v-else v-html="description"></p>
                                    <button
                                    v-if="shortDescription !== ''"
                                    @click="showMoreDescription = !showMoreDescription"
                                    class="btn btn-block mt10 btn-link text-primary">
                                    {{ showMoreDescription ? 'MEHR ANZEIGEN' : 'WENIGER ANZEIGEN' }}
                                </button>
                            </div>
                            <div class="about-content d-none d-lg-block">
                                <h5 class="post-heading">Beschreibung</h5>
                                <p v-html="description"></p>
                            </div>
                        </div>

                        <info-block class="col-12 d-lg-none" target="mobile" :content="content" :infos="infoBlockInfos"/>

                        <div class="col-12 d-lg-none">
                            <div class="card-simple mt40 mb20 mt-lg-0">
                                <h5 class="card-simple-title">Betreuungskosten</h5>
                                <p class="text-black">Krippenplatz pro Tagesbetreuungsstunde 36,05 Euro <br /> Elementar / Hort pro Tagesbetreuungsstunde 28,30 Euro</p>
                            </div>
                            <div class="card-simple mt20 mb20 mt-lg-0">
                                <h5 class="card-simple-title">ANmeldung</h5>
                                <p class="text-black">Melde dein Kind über das Kita-Portal der Stadt Wolfsburg an:</p>
                                <button class="btn btn-block btn-secondary mt20">Zur Anmeldung</button>
                            </div>
                        </div>

                        <div class="col-12">
                           <div class="key">
                            <h5 class="post-heading">ECKDATEN</h5>
                            <ul class="key-list">
                                <li class="key-list__item" v-if="paedagogischesKonzept!=''">
                                    <h5>Pädagogisches Konzept</h5>
                                    <p>{{Array.isArray(paedagogischesKonzept)?paedagogischesKonzept.join(', '):paedagogischesKonzept}}</p>
                                </li>
                                <li class="key-list__item" v-if="gruppengroesse!=''">
                                    <h5>Gruppengröße</h5>
                                    <p>{{Array.isArray(gruppengroesse)?gruppengroesse.join(', '):gruppengroesse}}</p>
                                </li>
                                <li class="key-list__item" v-if="religion!=''">
                                    <h5>Religion</h5>
                                    <p>{{Array.isArray(religion)?religion.join(', '):religion}}</p>
                                </li>
                                <li class="key-list__item" v-if="sprachen!=''">
                                    <h5>Fremdsprachen</h5>
                                    <p>{{Array.isArray(sprachen)?sprachen.join(', '):sprachen}}</p>
                                </li>
                                <li class="key-list__item" v-if="barrierefreiheit!=''">
                                    <h5>Barrierefreiheit</h5>
                                    <p>{{Array.isArray(barrierefreiheit)?barrierefreiheit.join(', '):barrierefreiheit}}</p>
                                </li>
                                <li class="key-list__item" v-if="heilpdaegogischeUnterstuetzung!=''">
                                    <h5>Heilpädagogische Unterstützung</h5>
                                    <p>{{Array.isArray(heilpdaegogischeUnterstuetzung)?heilpdaegogischeUnterstuetzung.join(', '):heilpdaegogischeUnterstuetzung}}</p>
                                </li>
                                <li class="key-list__item" v-if="mittagessen!=''">
                                    <h5>Mittagessen</h5>
                                    <p v-html="mittagessen"></p>
                                </li>
                                <li class="key-list__item" v-if="schliesszeiten!=''">
                                    <h5>Schließzeiten</h5>
                                    <p v-html="schliesszeiten"></p>
                                </li>
                                <li class="key-list__item" v-if="betreuungsart!=''">
                                    <h5>Betreuungsart</h5>
                                    <p>{{Array.isArray(betreuungsart)?betreuungsart.join(', '):betreuungsart}}</p>
                                </li>
                                <li class="key-list__item" v-if="betreuungsdauer!=''">
                                    <h5>Betreuungsdauer</h5>
                                    <p>{{Array.isArray(betreuungsdauer)?betreuungsdauer.join(', '):betreuungsdauer}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-3 ml-auto">
                <div class="card-simple d-none d-lg-block" v-if="betreuungskosten!==''">
                    <h5 class="card-simple-title">Betreuungskosten</h5>
                    <p class="text-black" v-html="betreuungskosten"></p>
                </div>

                <div class="card-simple mt20 d-none d-lg-block"v-if="anmeldung!==''">
                    <h5 class="card-simple-title">Anmeldung</h5>
                    <p class="text-black" v-html="anmeldung"></p>
                    <a class="btn btn-block btn-secondary mt20" target="_blank" alt="Link zur Anmeldung" v-if="linkZurAnmeldung!==''" :href="linkZurAnmeldung">Zur Anmeldung</a>
                </div>

                <a class="btn btn-block btn-w-icon icon-left btn-secondary mt20" target="_blank" alt="Link zur Anmeldung" :href="linkZurAnmeldung" v-if="anmeldung=='' && linkZurAnmeldung!==''">
                    Zur Anmeldung
                </a>

                <div class="card-simple mb20 mt20" v-if="weitereInfos!==''">
                    <h5 class="card-simple-title">Weitere Infos</h5>
                    <p class="text-black" v-html="weitereInfos"></p>
                </div>

                <a class="btn btn-block btn-w-icon icon-left btn-secondary" target="_blank" :href="flyerLink" v-if="flyerLink!==null">
                    <span class="material-icons-outlined" aria-hidden="true">file_download</span>
                    Flyer herunterladen
                </a>
            </div>
        </div>
    </div>
</div>

<div class="section pt-lg-0">
    <div class="container-fluid">
        <div class="row">

            <div class="col-12 col-lg-6">
                <detail-map v-if="content !== null && address !== null" :content="content" :addressObj="address"></detail-map>
            </div>

            <div class="col-12 col-lg-6">
                <contact-block class="mt40 mt-lg-0" :contentId="content.id" :contact="contactInfo" v-if="contactInfo!=null && content!==null"/>
            </div>
        </div>
    </div>
</div>

</div>
</template>

<script>
    import Resource from '@/api/resource';
    import { getContentsOfType } from '@/api/content';
    import { getFieldValues, showLoader, hideLoader, getFlyerLink } from '@/utils/helpers';
    const contentResource = new Resource('contents');
    import { screenSizeMixin } from '@/mixins/screenSizeMixin';

    export default {
        name: 'KitaDetail',
        mixins: [screenSizeMixin],
        components: {
            ShareButton: () => import('@/components/controls/ShareButton.vue'),
            InfoBlock: () => import('@/components/controls/InfoBlock.vue'),
            ProfileImageCarousel: () => import('@/components/controls/ProfileImageCarousel.vue'),
            DetailMap: () => import('@/components/controls/DetailMap.vue'),
            ContactBlock: () => import('@/components/controls/ContactBlock.vue'),
            Breadcrumb: () => import('@/components/layout/Breadcrumb.vue'),
        },
        data() {
            return {
                content: null,
                showMoreDescription: false,
                showMoreInfo: true,
            }
        },
        watch: {
          id(newId, oldId) {
            if (newId!=oldId) {
              this.getContent();
              this.isActiveLocation=true;
              this.$scrollTo();
          }
      }
  },
  created() {
      this.getContent();
      this.isActiveLocation=true;
  },
  computed: {
    id() {
        return this.$route.params.id;
    },
    title(){
        if(this.content!=null){
          return this.content.title;
      }
      return "";
  },
  description(){
    var desc = getFieldValues(this.content,'body');
    return desc!=null?desc:'';
},
infoBlockInfos(){
    var object = {};
    object.address = this.address;
    object.appointments = getFieldValues(this.content,'ffnungszeiten');

    object.linkTo = {
        url: getFieldValues(this.content,'url'),
        facebook: getFieldValues(this.content,'facebook'),
        instagram: getFieldValues(this.content,'instagram'),
        twitter: getFieldValues(this.content,'twitter'),
        youtube: getFieldValues(this.content,'youtube'),
    }
    return object;
},
address(){
    var address = getFieldValues(this.content,'adresse');
    return address!==null?address:'';
},
teaserImages(){
    var pictures = getFieldValues(this.content,'teaser-bilder');
    if(pictures != null){
      if(Array.isArray(pictures)){
        return pictures;
    }
    else{
        return [pictures];
    }
}
return null;
},
logo(){
    var logo = getFieldValues(this.content,'logo');
    if(logo!=null){
      logo = logo.charAt(0)!="/"?"/"+logo:logo;
  }
  return logo;
},
contactInfo(){
    var contact = getFieldValues(this.content,'kontaktinformationen');
    return contact!==null?contact:null;
},
weitereInfos(){
    var value = getFieldValues(this.content,'weitere_infos');
    return value!=null?value:'';
},
sprachen(){
    var value = getFieldValues(this.content,'sprachen');
    return value!=null?value:'';
},
mittagessen(){
    var value = getFieldValues(this.content,'mittagessen');
    return value!=null?value:'';
},
barrierefreiheit(){
    var value = getFieldValues(this.content,'barrierefreiheit');
    return value!=null?value:'';
},
betreuungskosten(){
    var value = getFieldValues(this.content,'betreuungskosten');
    return value!=null?value:'';
},
betreuungsart(){
    var value = getFieldValues(this.content,'betreuungsart');
    return value!=null?value:'';
},
betreuungsdauer(){
    var value = getFieldValues(this.content,'betreuungsdauer');
    return value!=null?value:'';
},
religion(){
    var value = getFieldValues(this.content,'religion');
    return value!=null?value:'';
},
gruppengroesse(){
    var value = getFieldValues(this.content,'gruppengrsse');
    return value!=null?value:'';
},
heilpdaegogischeUnterstuetzung(){
    var value = getFieldValues(this.content,'heilpdagogische_untersttzung');
    return value!=null?value:'';
},
paedagogischesKonzept(){
    var value = getFieldValues(this.content,'pdagogisches_konzept');
    return value!=null?value:'';
},
schliesszeiten(){
    var value = getFieldValues(this.content,'schliezeiten');
    return value!=null?value:'';
},
traeger(){
    var value = getFieldValues(this.content,'trger');
    return value!=null?value:'';
},
anmeldung(){
    var value = getFieldValues(this.content,'anmeldung');
    return value!=null?value:'';
},
linkZurAnmeldung(){
    var value = getFieldValues(this.content,'link_zur_anmeldung');
    return value!=null?value:'';
},
flyerLink(){
    return getFlyerLink(getFieldValues(this.content,'flyer_hochladen'),this.$backendUrl);
},
slides(){
    var images = [];
    if(this.logo != null){
        var item = {
            class: 'logo',
            image: this.$backendUrl+this.logo,
        };
        images.push(item);
    }

    if(this.teaserImages != null){
      for(var i = 0; i < this.teaserImages.length; i++){
        var item = {
            class: 'item',
            image: this.$backendUrl+this.teaserImages[i],
        };
        images.push(item);
    }
}

/*add the logo as the first image to the array*/
if(images.length == 0){
    return null;
}
return images;
},
showShareBtnInTitle(){
    if(this.$isMobileScreen){
        return true;
    }
    return false;
},
shortDescription() {
    if (this.description.length > this.$shortDescriptionWordCount) {
        this.showMoreDescription = true;

        return this.description.substring(0, this.$shortDescriptionWordCount) + '...';
    }
    return '';
},
},
methods:{
 getFieldValues, 
 showLoader, 
 hideLoader, 
 getContent() {
  this.loader = this.showLoader(this.loader);
  contentResource.get(this.id)
  .then(response => {
    this.content = response.data;
})
  .finally(() => {
    this.loader = this.hideLoader(this.loader);
});
},
}
}
</script>
<style lang="scss">
    @import '@/scss/_mixins.scss';
    @import '@/scss/_variables.scss';

    .profile{

        .container-fluid{
            @media (max-width: 991px) {
                padding-left: 0px;
                padding-right: 0px;
                margin-left: 0px;
                margin-right: 0px;
            }

            .profile-main__info {

              @media (max-width: 1360px) {
                  min-width: 540px;
                  margin-right: 60px;
              }

              @media (max-width: 991px) {
                padding-right: 15px;
                padding-left: 15px;
                margin-right: auto;
                margin-left: auto;
            }

            @media (max-width: 555px) {
                min-width: auto;
            }

            .info-list {
                margin-right: 20px;
            }

            h5 {
                @media (max-width: 991px) {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

</style>